import CreateService from '@/views/apps/my-pages/CRUDPages/services/CreateService.vue'
import EditService from '@/views/apps/my-pages/CRUDPages/services/EditService.vue'

import CreateEvent from '@/views/apps/my-pages/CRUDPages/events/CreateEvent.vue'
import EditEvent from '@/views/apps/my-pages/CRUDPages/events/EditEvent.vue'

import CreateBlog from '@/views/apps/my-pages/CRUDPages/blogs/CreateBlog.vue'
import EditBlog from '@/views/apps/my-pages/CRUDPages/blogs/EditBlog.vue'

export default [
  {
    path: '/my-pages/add-new',
    name: 'add-new',
    component: () => import('@/views/apps/my-pages/CRUDPages/AddNew.vue'),
    props: route => ({ query: route.query }),
  },
  {
    path: '/my-pages/add-edit-service',
    name: 'add-edit-services',
    component: () => import('@/views/apps/my-pages/CRUDPages/AddEditService.vue'),
    props: route => ({ query: route.query }),
  },
  {
    path: '/my-pages/services/create',
    name: 'CreateServices',
    component: CreateService
  },
  {
    path: '/my-pages/services/create',
    name: 'CreateService',
    component: CreateService
  },
  {
    path: '/my-pages/services/edit/:id',
    name: 'EditService',
    component: EditService
  },
  {
    path: '/my-pages/events/create',
    name: 'CreateEvent',
    component: CreateEvent
  },
  {
    path: '/my-pages/events/edit/:id',
    name: 'EditEvent',
    component: EditEvent
  },
  {
    path: '/my-pages/blogs/create',
    name: 'CreateBlog',
    component: CreateBlog
  },
  {
    path: '/my-pages/blogs/edit/:id',
    name: 'EditBlog',
    component: EditBlog
  },
  {
    path: '/my-pages/add-edit-event',
    name: 'add-edit-events',
    component: () => import('@/views/apps/my-pages/CRUDPages/AddEditEvent.vue'),
    props: route => ({ query: route.query }),
  },
  {
    path: '/my-pages/add-edit-news',
    name: 'add-edit-news',
    component: () => import('@/views/apps/my-pages/CRUDPages/AddEditBlog.vue'),
    props: route => ({ query: route.query }),
  },
  {
    path: '/my-pages/services',
    name: 'apps-services',
    component: () => import('@/views/apps/my-pages/services/Services.vue'),
  },
  {
    path: '/my-pages/services/service-details',
    name: 'service-details',
    component: () => import('@/views/apps/my-pages/services/ServiceDetails.vue'),
    props: route => ({ query: route.query }),
  },
  {
    path: '/my-pages/events',
    name: 'apps-events',
    component: () => import('@/views/apps/my-pages/events/Events.vue'),
    props: route => ({ query: route.query }),
  },
  {
    path: '/my-pages/events/event-details',
    name: 'event-details',
    component: () => import('@/views/apps/my-pages/events/EventDetails.vue'),
    props: route => ({ query: route.query }),
  },
  {
    path: '/my-pages/blogs',
    name: 'apps-blog',
    component: () => import('@/views/apps/my-pages/blog/Blog.vue'),
  },
  {
    path: '/my-pages/tags',
    name: 'apps-tags',
    component: () => import('@/views/apps/my-pages/tags/Tags.vue'),
  },
  {
    path: '/my-pages/categories',
    name: 'apps-categories',
    component: () => import('@/views/apps/my-pages/categories/Categories.vue'),
  },
  {
    path: '/my-pages/promotions',
    name: 'apps-promotions',
    component: () => import('@/views/apps/my-pages/promotions/Promotions.vue'),
  },
  {
    path: '/my-pages/states',
    name: 'apps-states',
    component: () => import('@/views/apps/my-pages/states/States.vue'),
  },
  {
    path: '/my-pages/users',
    name: 'apps-users',
    component: () => import('@/views/apps/my-pages/users/Users.vue'),
  },
  {
    path: '/services/merchant',
    name: 'merchant-control',
    component: () => import('@/views/apps/my-pages/services/ServiceMerchant.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
]
