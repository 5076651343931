<template>
  <div>
    <div>
      <b-card>
        <div class="w-100 text-uppercase">
          <h4 class="mb-3">Add New News</h4>
        </div>
        <form @submit.prevent="handleSubmit">
          <b-form-group>
            <!-- Title & Sub_Title -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="title" class="font-weight-bolder"
                      >Title :</label
                    >
                    <b-form-input
                      v-model="blog.title"
                      id="title"
                      placeholder="Enter Blog Title"
                    >
                    </b-form-input>
                    <p v-if="errors.title" class="text-danger">{{ errors.title[0] }}</p>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="subTitle" class="font-weight-bolder"
                      >Sub Title :</label
                    >
                    <b-form-input
                      v-model="blog.sub_title"
                      id="subTitle"
                      placeholder="Enter Blog Sub Title"
                    >
                    </b-form-input>
                    <p v-if="errors.sub_title" class="text-danger">{{ errors.sub_title[0] }}</p>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Categories -->
            <div class="mb-3">
              <label for="categories" class="font-weight-bolder"
                >Categories :</label
              >
              <b-form-select v-model="blog.category_id" @change="tagsFilter">
                <b-form-select-option disabled value=""
                  >Please Select a category</b-form-select-option
                >
                <b-form-select-option
                  v-for="c in categories"
                  :key="c.id"
                  :value="c.id"
                  >{{ c.name }}</b-form-select-option
                >
              </b-form-select>
              <p v-if="errors.category_id" class="text-danger">{{ errors.category_id[0] }}</p>
            </div>
            <!-- Tags -->
            <template v-if="blog.category_id.length !== 0">
              <div class="mb-3">
                <label for="tags" class="font-weight-bolder">Tags :</label>
                <b-form-checkbox-group
                  v-model="selectedTags"
                  buttons
                  class="d-flex align-items-center"
                  @change="changeArrToString"
                >
                  <b-form-checkbox
                    v-for="t in tagsFilterArr"
                    :key="t.id"
                    :value="t.id"
                    size="sm"
                    class="rounded-pill mr-2 tag"
                    button
                    button-variant="outline-primary"
                  >
                    {{ t.name }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
            </template>

            <!-- Description -->
            <div class="mb-3">
              <label for="des" class="font-weight-bolder">Description :</label>
              <vue-editor :editorToolbar="quillBar" v-model="blog.description"></vue-editor>
              <p v-if="errors.description" class="text-danger">{{ errors.description[0] }}</p>
            </div>

            <!-- Booking_status & Publish_status -->
            <b-row class="mb-3">
              <b-col>
                <label for="author" class="font-weight-bolder">Author :</label>
                <b-form-input
                  v-model="blog.author"
                  id="author"
                  placeholder="Enter Author's Name"
                >
                </b-form-input>
                <p v-if="errors.author" class="text-danger">{{ errors.author[0] }}</p>
              </b-col>

              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder"
                    >Publish :</label
                  >
                  <b-form-checkbox
                    v-model="publish"
                    @change="toggle(publish)"
                    name="check-publish-button"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <!-- Posted Date -->
            <b-row>
              <b-col>
                <div>
                  <label for="posted_at" class="font-weight-bolder"
                    >Posted Date :</label
                  >
                  <b-form-datepicker
                    id="posted_at"
                    v-model="blog.posted_at"
                    class="mb-2"
                  ></b-form-datepicker>
                  <p v-if="errors.posted_at" class="text-danger">{{ errors.posted_at[0] }}</p>
                </div>
              </b-col>

              <!-- Photos -->
              <b-col class="mb-4">
                <label for="contact-info" class="font-weight-bolder"
                  >Photos :</label
                >
                <div>
                  <MyPhotoUpload :images="blog.photos" @getPhotoId="uploadPhoto" @delete="removeImage" />
                </div>
              </b-col>
            </b-row>
          </b-form-group>

          <div class="d-flex flex-column flex-md-row">
            <div class="w-100 text-center mb-2 mb-md-0">
              <b-button
                type="button"
                variant="outline-primary"
                @click="() => router.go(-1)"
                class="col-md-10 col-lg-8"
              >
                <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
                <span>Back</span>
              </b-button>
            </div>

            <div class="w-100 text-center">
              <b-button
                :disabled="loading"
                variant="primary"
                class="col-md-10 col-lg-8"
                type="submit"
              >
              <b-spinner v-if="loading"></b-spinner>
                <span v-else>Submit</span>
              </b-button>
            </div>
          </div>
        </form>
      </b-card>
    </div>
  </div>
</template>
    
  <script>
import store from "@/store";
import { VueEditor } from "vue2-quill-editor";
import MyPhotoUpload from "../../components/MyPhotoUpload.vue";
import { useRouter } from "@core/utils/utils";
import { ref, reactive } from "@vue/composition-api";
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTimepicker,
  BFormDatepicker,
  BSpinner
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTimepicker,
    BFormDatepicker,
    VueEditor,
    MyPhotoUpload,
  },
  setup() {
    const { router } = useRouter();
    const checked = ref(true);
    const publish = ref(true);
    const categories = ref([]);
    const tags = ref([]);
    const tagsFilterArr = ref([]);
    const mulImgs = ref([]);
    const selectedTags = ref([]);
    const loading = ref(false);
    const blog = ref({
      title: "",
      category_id: "",
      sub_title: "",
      description: "",
      author: "",
      status: "publish",
      posted_at: "",
      tags: [],
      photos: [],
    });
    const errors = ref({});
    const quillBar = [['link', 'image' , 'video'],['bold', 'italic', 'underline'],[{ 'list': 'ordered'}, { 'list': 'bullet' }],['code-block'] , ['blockquote'],[{ 'header': 1 }, { 'header': 2 }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'size': ['small', false, 'large', 'huge'] }],[{ 'header': [1, 2, 3, 4, 5, 6, false] }],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }],[{ 'align': [] }],];

    const getCategories = () => {
      store.dispatch("app-mypages/fetchDatas", "categories")
        .then((res) => {
          categories.value = res.data.data;
        }).catch((res) => {
          console.log(res);
        });
    };
    getCategories();

    const getTags = () => {
        store.dispatch("app-mypages/fetchDatas", "tags")
        .then((response) => {
          tags.value = response.data.data;
        });
    }
    getTags();

    const tagsFilter = () => {
      tagsFilterArr.value = tags.value.filter((tag) => {
        return blog.category_id == tag.category_id;
      });
    };
    // service categories
    const changeArrToString = () => {
      // blog.tags = JSON.stringify(selectedTags.value);
      blog.tags = selectedTags.value;
    };
    const toggle = (c = checked.value, p = publish.value) => {
      p ? (blog.value.status = "publish") : (blog.value.status = "unpublish");
    };
    const uploadPhoto = (photoIds) => {
      mulImgs.value = [...mulImgs.value, photoIds];
      blog.value.photos = mulImgs.value;
    };
    const removeImage = (path , index) => {
      mulImgs.value = mulImgs.value.filter((img , i) => index != i);
      blog.value.photos = mulImgs.value;
      console.log(blog.value.photos)
    }


    //Data Posting
    const handleSubmit = () => {
      loading.value = true;
      store
        .dispatch("app-mypages/postDatas", {
          path: "news",
          payload: blog.value,
        })
        .then(() => {
      loading.value = false;
          router.push('/my-pages/blogs');
        }).catch((res) => {
      loading.value = false;
            errors.value = res.response?.data?.errors;
        });
    };

    return {
      checked,
      errors,
      publish,
      categories,
      tags,
      tagsFilterArr,
      tagsFilter,
      handleSubmit,
      toggle,
      uploadPhoto,
      selectedTags,
      changeArrToString,
      blog,
      selectedTags,
      router,
      removeImage,
      quillBar,
      loading
    };
  },
};
</script>
  <style scoped>
.tag {
  padding: 0.5rem 1.5rem;
  flex: 0 0 auto;
}
</style>