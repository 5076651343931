import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import mypages from './routes/my-pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-ecommerce' } },
    ...apps,
    ...dashboard,
    ...mypages,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  console.log(isLoggedIn)
  // if (!isLoggedIn) {
  //   next({ name: 'auth-login' })
  // } else if (isLoggedIn && !canNavigate(to)) {
  //   // If logged in => not authorized
  //   next({ name: 'misc-not-authorized' })
  // } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData && userData.role_name))
  // } else {
  //   next()
  // }

    if (!isLoggedIn && to.name != 'auth-login') {
      console.log(to.name)
      return next({ name: 'auth-login' })
    }
    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData && userData.role_name))
  }

  return next()
})



router.beforeEach((to, from, next) => {
  if (!Object.prototype.hasOwnProperty.call(to.query, 'page')) {
    next({
      ...to,
      query: {
        // This line is used to retain other query if there is any
        ...to.query,
        page: 1,
      },
    });
  } else {
    next();
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
