<template>
  <div>
    <div>
      <vue-upload-multiple-image
        @upload-success="uploadImageSuccess"
        @before-remove="beforeRemove"
        dragText="Drag images (multiple)"
        browseText="(or) Select"
        primaryText="First Photo"
        :data-images="dataImages"
      ></vue-upload-multiple-image>
      <p class="text-danger" v-if="uploading" >Uploading . . .</p>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { ref } from "@vue/composition-api";
import { BCard, BButton, BSpinner, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    VueUploadMultipleImage,
  },
  props : {
    images : Array
  },
  setup( props , { emit }) {
    const photoId = ref("");
    const uploading = ref(false);
    console.log(props.images)
    const dataImages = props.images && props.images.length ? props.images.map((img) => {
      let obj = {
        path : img.name
      }
      return obj;
    }) : [];
    console.log(dataImages)
    const uploadImageSuccess = (formData, index, fileList) => {
      uploading.value = true;
      const file = formData.get("file");
      const url = URL.createObjectURL(file);
      console.log(url);
      formData.set("name", file)

      store
        .dispatch("app-mypages/postDatas", { path: "photo", payload: formData })
        .then((response) => {
          photoId.value = response.data.data.id;
          console.log(photoId.value)
          emit("getPhotoId", photoId.value);
          uploading.value = false;
        });
    };

    const beforeRemove = (index, done, fileList) => {
      emit('delete' , fileList[index].path , index);
      done();
      // if (true) {
      //   store
      //     .dispatch("app-mypages/delData", { path: "photo", id: photoId.value })
      //     .then((response) => {
      //       done();
      //     });
      // }
    };

    // const editImage = (formData, index, fileList) => {
    //   imageFormFileData.value = fileList[0].path;
    // };

    return {
      uploadImageSuccess,
      beforeRemove,
      dataImages,
      uploading
      // editImage,
    };
  },
};
</script>
  