<template>
  <div>
    <div>
      <b-card>
        <div class="w-100 text-uppercase">
          <h4 class="mb-3">Edit Event</h4>
        </div>
        <form v-if="event.id" @submit.prevent="handleSubmit">
          <b-form-group class="mb-5">
            <!-- Title & Sub_Title -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="title" class="font-weight-bolder"
                      >Title :</label
                    >
                    <b-form-input
                      v-model="event.title"
                      id="title"
                      placeholder="Enter Event Title"
                    >
                    </b-form-input>
                    <p v-if="errors.title" class="text-danger">{{ errors.title[0] }}</p>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="subTitle" class="font-weight-bolder"
                      >Sub Title :</label
                    >
                    <b-form-input
                      v-model="event.sub_title"
                      id="subTitle"
                      placeholder="Enter Event Sub Title"
                    >
                    </b-form-input>
                    <p v-if="errors.sub_title" class="text-danger">{{ errors.sub_title[0] }}</p>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Categories -->
            <div class="mb-3">
              <label for="categories" class="font-weight-bolder"
                >Categories :</label
              >
              <b-form-select v-model="event.category_id" class="mb-3">
                <b-form-select-option :value="null"
                  >Please select an option</b-form-select-option
                >
                <b-form-select-option
                  v-for="c in categories"
                  :key="c.id"
                  :value="c.id"
                  >{{ c.name }}</b-form-select-option
                >
              </b-form-select>
              <p v-if="errors.category_id" class="text-danger">{{ errors.category_id[0] }}</p>
            </div>
            <!-- Description -->
            <div class="mb-3">
              <label for="des" class="font-weight-bolder">Description :</label>
              <vue-editor :editorToolbar="quillBar" v-model="event.description"></vue-editor>
              <p v-if="errors.description" class="text-danger">{{ errors.description[0] }}</p>
            </div>

            <!-- Contact Info -->
            <div class="mb-3">
              <label for="contact-info" class="font-weight-bolder"
                >Contact Info :</label
              >
              <b-form-input
                v-model="event.contact_info"
                id="contact-info"
                placeholder="Enter Contact Info"
              >
              </b-form-input>
              <p v-if="errors.contact_info" class="text-danger">{{ errors.contact_info[0] }}</p>
            </div>

            <!-- Email & Phone -->
            <b-row class="mb-3">
              <b-col>
                <div>
                  <label for="email" class="font-weight-bolder">Email :</label>
                  <b-form-input
                    v-model="event.email"
                    id="email"
                    type="email"
                    placeholder="example@gmail.com"
                  >
                  </b-form-input>
                  <p v-if="errors.email" class="text-danger">{{ errors.email[0] }}</p>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="phno" class="font-weight-bolder">Phone :</label>
                  <b-form-input
                    v-model="event.phone"
                    id="phno"
                    placeholder="Phone Number"
                  >
                  </b-form-input>
                  <p v-if="errors.phone" class="text-danger">{{ errors.phone[0] }}</p>
                </div>
              </b-col>
            </b-row>

            <!-- Latitude & Longitude -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="lat" class="font-weight-bolder"
                      >Latitude :</label
                    >
                    <b-form-input
                      v-model="event.lat"
                      id="lat"
                      placeholder="example - 16.768148"
                    >
                    </b-form-input>
                    <p v-if="errors.lat" class="text-danger">{{ errors.lat[0] }}</p>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="lng" class="font-weight-bolder"
                      >Longitude :</label
                    >
                    <b-form-input
                      v-model="event.lng"
                      id="lng"
                      placeholder="example - 96.174930"
                    >
                    </b-form-input>
                    <p v-if="errors.lng" class="text-danger">{{ errors.lng[0] }}</p>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Booking_status & Publish_status -->
            <b-row class="mb-3">
              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder"
                    >Ticket Mode :</label
                  >
                  <b-form-checkbox
                    v-model="checked"
                    @change="toggle(checked)"
                    name="check-button"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder"
                    >Publish :</label
                  >
                  <b-form-checkbox
                    v-model="publish"
                    @change="toggle(publish)"
                    name="check-publish-button"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <!-- Opening_Date & Closing_Date -->
            <b-row>
              <b-col>
                <div>
                  <label for="e_start" class="font-weight-bolder"
                    >Event Start Date :</label
                  >
                  <b-form-datepicker
                    id="e_start"
                    v-model="event.opening_date"
                    class="mb-2"
                    required
                  ></b-form-datepicker>
                  <p v-if="errors.opening_date" class="text-danger">{{ errors.opening_date[0] }}</p>
                </div>
              </b-col>

              <b-col>
                <div>
                  <label for="e_end" class="font-weight-bolder"
                    >Event End Date :</label
                  >
                  <b-form-datepicker
                    id="e_end"
                    v-model="event.closing_date"
                    class="mb-2"
                    required
                  ></b-form-datepicker>
                  <p v-if="errors.closing_date" class="text-danger">{{ errors.title[0] }}</p>
                </div>
              </b-col>
            </b-row>

            <!-- Photos -->
            <div class="mb-4">
              <label for="contact-info" class="font-weight-bolder"
                >Photos :</label
              >
              <div v-if="event.id">
                <MyPhotoUpload :images="previewImages" @delete="removeImage" @getPhotoId="uploadPhoto" />
              </div>
            </div>
          </b-form-group>

          <div class="d-flex flex-column flex-md-row">
            <div class="w-100 text-center mb-2 mb-md-0">
              <b-button
                type="button"
                variant="outline-primary"
                @click="() => router.go(-1)"
                class="col-md-10 col-lg-8"
              >
                <!-- <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner> -->
                <span>Back</span>
              </b-button>
            </div>

            <div class="w-100 text-center">
              <b-button
              :disabled="loading"
                variant="primary"
                class="col-md-10 col-lg-8"
                type="submit"
              >
              <b-spinner v-if="loading"></b-spinner>  
                <span v-else>Submit</span>
              </b-button>
            </div>
          </div>
        </form>
        <div class="d-flex justify-content-center" v-else>
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
      </b-card>
    </div>
  </div>
</template>
      
    <script>
import store from "@/store";
import { VueEditor } from "vue2-quill-editor";
import MyPhotoUpload from "../../components/MyPhotoUpload.vue";
import { ref } from "@vue/composition-api";
import {
  BCard,
  BSpinner,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTimepicker,
  BFormDatepicker,
} from "bootstrap-vue";
import { useRouter } from '@core/utils/utils'
export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTimepicker,
    BFormDatepicker,
    VueEditor,
    MyPhotoUpload,
  },
  setup() {
    const {router , route}  = useRouter();
    const checked = ref(true);
    const publish = ref(true);
    const categories = ref([]);
    const mulImgs = ref([]);
    const loading = ref(false);
    const event = ref({
      title: "",
      category_id: "",
      sub_title: "",
      description: "",
      contact_info: "",
      email: "",
      phone: "",
      lat: "",
      lng: "",
      ticket_mode: 1,
      status: "active",
      photos: [],
      opening_date: "",
      closing_date: "",
    });
    const errors = ref({});
    const previewImages = ref([]);
    const quillBar = [['link', 'image' , 'video'],['bold', 'italic', 'underline'],[{ 'list': 'ordered'}, { 'list': 'bullet' }],['code-block'] , ['blockquote'],[{ 'header': 1 }, { 'header': 2 }],[{ 'script': 'sub'}, { 'script': 'super' }],[{ 'size': ['small', false, 'large', 'huge'] }],[{ 'header': [1, 2, 3, 4, 5, 6, false] }],[{ 'color': [] }, { 'background': [] }],[{ 'font': [] }],[{ 'align': [] }],];

    const fetchEventDetails = () => {
      store.dispatch("app-mypages/fetchDetails", {
          path: "events",
          id: route.value.params.id,
        })
        .then((response) => {
          event.value = response.data.data;
          previewImages.value = event.value.photos;
          // console.log(previewImages.value)
          let photos = event.value.photos;
          event.value.photos = [];
          photos.forEach((photo) => {
            event.value.photos.push(photo.id)
            mulImgs.value.push(photo.id)
          })
        })
        .catch((res) => {
          console.log(res);
        });
    };
    fetchEventDetails();

    const getCategories = () => {
      store
        .dispatch("app-mypages/fetchDatas", "categories")
        .then((res) => {
          categories.value = res.data.data;
        })
        .catch((res) => {
          console.log(res);
        });
    };
    getCategories();

    const toggle = (c = checked.value, p = publish.value) => {
      c ? (event.value.ticket_mode = 1) : (event.value.ticket_mode = 0);
      p ? (event.value.status = "active") : (event.value.status = "inactive");
    };

    const uploadPhoto = (photoIds) => {
      mulImgs.value = [...mulImgs.value, photoIds];
      event.value.photos = mulImgs.value;
    };

    const removeImage = (path , index) => {
        previewImages.value = previewImages.value.filter(img => path != img.name)
        mulImgs.value = mulImgs.value.filter((img , i) => i != index)
        event.value.photos = mulImgs.value;
      }
    //   //Data Posting
    const handleSubmit = () => {
      loading.value= true;
      store.dispatch("app-mypages/updateData", {
          path: "events",
          payload: event.value,
        })
        .then(() => {
          loading.value = false;
          router.push('/my-pages/events?page=1')
        }).catch((res) => {
          loading.value = false;
            errors.value = res.response?.data?.errors
        });
    };
    return {
      event,
      errors,
      checked,
      publish,
      categories,
      handleSubmit,
      toggle,
      uploadPhoto,
      removeImage,
      previewImages,
      router,
      quillBar,
      loading
    };
  },
};
</script>